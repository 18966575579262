import {
  SIZE_TEXT,
  WEBCHAT_MAX_FILE_BYTES,
  WEBCHAT_MAX_IMAGE_BYTES,
  WEBCHAT_MAX_VIDEO_BYTES,
} from "@configs";

export const checkFileSize = (
  file: File
): {
  status: boolean;
  message: string;
} => {
  // image
  if (file.type.includes("image")) {
    return {
      status: file.size <= WEBCHAT_MAX_IMAGE_BYTES,
      message:
        file.size <= WEBCHAT_MAX_IMAGE_BYTES
          ? ""
          : `Attachment size should less than ${SIZE_TEXT.IMAGE}`,
    };
  }

  // video
  if (file.type.includes("video")) {
    return {
      status: file.size <= WEBCHAT_MAX_VIDEO_BYTES,
      message:
        file.size <= WEBCHAT_MAX_VIDEO_BYTES
          ? ""
          : `Attachment size should less than ${SIZE_TEXT.VIDEO}`,
    };
  }

  return {
    status: file.size <= WEBCHAT_MAX_FILE_BYTES,
    message: `Attachment size should less than ${SIZE_TEXT.FILE}`,
  };
};
