import React from "react";
import { faCircleExclamation } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EMessageStatus, IAttachment } from "@types";
import { DefaultExtensionType, defaultStyles, FileIcon } from "react-file-icon";
import { createDataAttr } from "@utils";

export type IAttachmentMessageProps = {
  message: IAttachment;
};

export const AttachmentMessage = ({ message }: IAttachmentMessageProps) => {
  // error
  if (message.status === EMessageStatus.ERROR) {
    return (
      <div {...createDataAttr("attachment-message-error")}>
        <div
          className="flex h-[50px] w-[240px] items-center justify-center rounded-[10px] bg-[#dbdbdb] object-cover"
          {...createDataAttr("attachment-error-message")}
        >
          <FontAwesomeIcon icon={faCircleExclamation} fontSize={24} />
        </div>
      </div>
    );
  }
  return (
    <a download href={message.url} {...createDataAttr("attachment-message")}>
      <div className="flex h-[80px] gap-x-[8px] rounded-[4px] border-[1px] border-solid border-white p-[12px]">
        <FileIcon
          {...defaultStyles[message.ext as DefaultExtensionType]}
          extension={message.ext}
        />
        <span className="line-clamp-2 h-[50px] min-w-[135px] max-w-[135px] overflow-hidden text-ellipsis">
          {message.name}
        </span>
      </div>
    </a>
  );
};
