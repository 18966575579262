import React, { useState, useEffect, useRef } from "react";
import { useComposer } from "@containers/chat/Composer/useComposer";
import { IMessageAction, IQuickReply, IQuickReplyItem } from "@types";
import { createDataAttr } from "@utils";

const QuickReply = ({
  quickReply,
  onSendAction,
}: {
  quickReply?: IQuickReply;
  onSendAction: (action: IMessageAction) => Promise<void>;
}) => {
  const footerRef = useRef<HTMLDivElement>(null);
  const onQuickReplyClick = (action: IMessageAction) => {
    onSendAction(action);
  };

  if (!quickReply) return <></>;
  return (
    <div
      className="flex flex-nowrap gap-4 overflow-auto pl-14"
      style={{
        width: window.innerWidth - 16,
      }}
      ref={footerRef}
      {...createDataAttr("quick-reply")}
    >
      {quickReply.items.map((item: IQuickReplyItem) => (
        <button
          key={item.action.id}
          onClick={() => onQuickReplyClick(item.action)}
          className="mb-2 mt-2 whitespace-nowrap rounded-full border-transparent bg-white px-4 py-2 font-bold text-blue-700"
          {...createDataAttr("quick-button")}
        >
          {item.action.label}
        </button>
      ))}
    </div>
  );
};

export default QuickReply;
