import { ISdkIframePostMessage } from "sdk";
import { IFRAME_SENDER } from "@constants/iframe";
import { useEffect } from "react";

type IUserIframeMessageParams = {
  listener: (data: ISdkIframePostMessage) => void;
};

export const useIframeMessage = ({ listener }: IUserIframeMessageParams) => {
  useEffect(() => {
    window.addEventListener("message", onIframeMessage, false);

    return () => {
      window.removeEventListener("message", onIframeMessage, false);
    };
  }, []);

  const onIframeMessage = (event: MessageEvent) => {
    const { data }: { data: ISdkIframePostMessage } = event;
    // get rid of the type that is not from ams_sdk
    if (!data.sender) return;
    if (data.sender !== IFRAME_SENDER) return;
    listener(data);
  };
};
