import { createDataAttr } from "@utils";
import React from "react";
import ContentLoader from "react-content-loader";
export type IImageLoaderProps = {
  width?: number;
  height?: number;
  speed?: number;
};

export const ImageLoader = ({
  width,
  height,
  speed = 2,
}: React.PropsWithChildren<IImageLoaderProps>) => {
  const defualtWidth = width ? width : 200;
  const defualtHeight = height ? height : 200;
  return (
    <ContentLoader
      speed={speed}
      viewBox={`0 0 ${defualtWidth} ${defualtHeight}`}
      height={defualtHeight}
      width={defualtWidth}
      backgroundColor="#dbdbdb"
      foregroundColor="#ecebeb"
      {...createDataAttr("image-loader")}
    >
      <rect
        x="0"
        y="0"
        rx="0"
        ry="0"
        width={defualtWidth}
        height={defualtHeight}
      />
    </ContentLoader>
  );
};
