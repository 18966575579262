import { IRichmenuAction } from "@types";
import { useState, useEffect } from "react";
import { useChatContext } from "..";
import { useActor } from "@xstate/react";
import { generateActionMessage } from "@fns";
import { useAppStore } from "@store/appStore";
import { shallow } from "zustand/shallow";

export const useRichmenu = () => {
  const machineService = useChatContext();
  const { send } = machineService.chatService;

  const { token, room } = useAppStore(
    (state) => ({
      token: state.token,
      room: state.room,
    }),
    shallow
  );

  return {
    onSendRichmenuAction: async (action: IRichmenuAction) => {
      try {
        const outgoing = await generateActionMessage(action, room, token);
        // if null
        if (!outgoing) return;
        send("SEND_MESSAGE", outgoing);
      } catch (error) {
        console.error("error create message action ", error);
      }
    },
  };
};
