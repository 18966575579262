export const createCssFromLink = (cssLinks?: string[]) => {
  if (!cssLinks) return "";
  for (const link of cssLinks) {
    if (!link) continue;
    if (!link.startsWith("http")) {
      continue;
    }
    // check if it is css link
    if (!link.endsWith(".css")) continue;
    const linkEl = document.createElement("link");
    linkEl.setAttribute("href", link);
    linkEl.setAttribute("rel", "stylesheet");
    document.head.appendChild(linkEl);
  }
};
