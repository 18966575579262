import { createDataAttr } from "@utils";
import dayjs from "dayjs";
import React from "react";

export const enum EDateTextDirection {
  LEFT = "left",
  RIGHT = "right",
}

export type IDateTextProps = {
  direction: EDateTextDirection;
  str?: string;
};

export const DateText = ({
  direction,
  str,
}: React.PropsWithChildren<IDateTextProps>) => {
  if (!str) return null;
  const date = dayjs(str).format("D/M/YY HH:mm A");
  return (
    <>
      <p
        className={`pt-[10px] text-[13px] text-[#999999] ${
          direction === "left" ? "text-left" : "text-right"
        }`}
        {...createDataAttr("date-text")}
      >
        {date === "Invalid Date" ? dayjs().format("D/M/YY HH:mm A") : date}
      </p>
    </>
  );
};
