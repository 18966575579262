import React, { createContext, useCallback, useContext } from "react";
import { Header } from "./Header";
import { MessageBox } from "./MessageBox";
import { Composer } from "./Composer";
import { useInterpret } from "@xstate/react";
import { InterpreterFrom } from "xstate";
import { chatMachine } from "./machine";
import { useChat } from "./useChat";
import { isOpenDevTool } from "@utils";
import { useAppStore } from "@store/appStore";
import { shallow } from "zustand/shallow";
import { RichMenu } from "./Richmenu";

export const ChatContext = createContext({
  chatService: {} as InterpreterFrom<typeof chatMachine>,
});

export const useChatContext = () => useContext(ChatContext);

export const Chat = () => {
  const { richmenu, isShowHeader, richmenuState } = useChat();
  const { room, user } = useAppStore((state) => {
    return {
      room: state.room,
      user: state.user,
    };
  }, shallow);

  const machine = useCallback(() => chatMachine(room, user), [room, user]);
  const chatService = useInterpret(machine, {
    devTools: isOpenDevTool(),
  });
  return (
    <ChatContext.Provider value={{ chatService }}>
      <article className="flex h-screen flex-col">
        {isShowHeader && <Header />}
        <MessageBox />

        {richmenu ? <RichMenu /> : null}

        <Composer richmenuState={richmenuState} />
      </article>
    </ChatContext.Provider>
  );
};
