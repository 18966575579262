import { ACCEPT_FILE_TYPES } from "@configs";
import { IAppSettings, IUser } from "@types";
import { IRoom } from "@types";
import { create } from "zustand";
import { persist } from "zustand/middleware";

type IAppStore = {
  token: string;
  user: IUser;
  settings: IAppSettings;
  room: IRoom;
  setSettings: (settings: IAppSettings) => void;
  setUser: (user: IUser) => void;
  setRoom: (room: IRoom) => void;
  setToken: (token: string) => void;
  clearStorage: () => void;
};

export const useAppStore = create<IAppStore>()(
  persist(
    (set, get) => ({
      token: "",
      user: {} as IUser,
      room: {} as IRoom,
      settings: {
        channelId: "",
        mode: "",
        botImage: "",
        botName: "",
        acceptFileTypes: ACCEPT_FILE_TYPES,
      },
      setSettings: (settings: IAppSettings) => {
        set((state) => {
          return {
            ...state,
            settings: {
              ...state.settings,
              ...settings,
            },
          };
        });
      },
      setUser: (user: IUser) => {
        set((state) => {
          return {
            ...state,
            user,
          };
        });
      },
      setRoom: (room: IRoom) => {
        set((state) => {
          return {
            ...state,
            room: room,
          };
        });
      },

      setToken: (token: string) => {
        set((state) => {
          return {
            ...state,
            token,
          };
        });
      },
      clearStorage: () => {
        set((state) => {
          return {
            ...state,
            token: "",
            user: {} as IUser,
            room: {} as IRoom,
          };
        });
        localStorage.removeItem("app-storage");
      },
    }),
    {
      name: "app-storage",
      partialize: (state) => ({ token: state.token }),
    }
  )
);
