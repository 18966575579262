import { createDataAttr } from "@utils";
import React from "react";
import LoadingOverlay, { LoadingOverlayProps } from "react-loading-overlay";

interface ILoading extends LoadingOverlayProps {
  children?: React.ReactNode;
}

export const Loading: React.FC<ILoading> = (props) => (
  <LoadingOverlay {...props} {...createDataAttr("loading-overlay")} />
);
