import { Livechat } from "@api";
import { ILivechatGuestCustomFieldAPI } from "@ams/rocket-sdk";
import { IGrantUserParams, IUser } from "@types";
import { compact } from "lodash";

export const grantUser = async (token: string, params: IGrantUserParams) => {
  const customFieldArr: ILivechatGuestCustomFieldAPI[] = [];
  for (const [key, value] of Object.entries(params)) {
    if (!value) {
      continue;
    }
    customFieldArr.push(generateCustomField(key, value, true));
  }

  const result: IUser = await Livechat.grantVisitor({
    visitor: {
      token,
      customFields: compact(customFieldArr),
    },
  });
  return result;
};

export const generateCustomField = (
  key: string,
  value: string,
  isOverwrite: boolean
) => {
  return {
    key,
    value,
    overwrite: isOverwrite,
  };
};
