import { createDataAttr } from "@utils";
import React from "react";

export type IDeliveryStatusProps = {
  isSending: boolean;
  isError: boolean;
  sendingText: string;
  errorText: string;
};

export const DeliveryStatus = ({
  isSending,
  isError,
  sendingText,
  errorText,
}: React.PropsWithChildren<IDeliveryStatusProps>) => {
  return (
    <>
      {isSending && (
        <div
          className="pr-[10px] pt-[10px] text-[13px] text-[#999999]"
          {...createDataAttr("deliver-status-sending")}
        >
          {sendingText}
        </div>
      )}

      {isError && (
        <div
          className="pr-[10px] pt-[10px] text-[13px] text-red-500"
          {...createDataAttr("deliver-status-error")}
        >
          {errorText}
        </div>
      )}
    </>
  );
};
