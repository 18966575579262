import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useChatContext } from "..";
import { EMessageType } from "@types";
import { useAppStore } from "@store/appStore";
import { shallow } from "zustand/shallow";
import { EComposerMode, TEXT_AREA_MAX_HEIGHT } from "@constants";
import { isEmpty } from "lodash";
import { otgMsgGenerator } from "@fns";
import { useRichmenuStore } from "@store/richmenuStore";

export const useComposer = () => {
  const { richmenu, isOpenRichmenu, toggle } = useRichmenuStore((state) => {
    return {
      richmenu: state.richmenu,
      isOpenRichmenu: state.isOpenRichmenu,
      toggle: state.toggleRichmenu,
    };
  }, shallow);
  const [mode, setMode] = useState(EComposerMode.CHAT);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [message, setMessage] = useState("");
  const machineService = useChatContext();
  const { send } = machineService.chatService;
  const isRichmenuInit = useRef(false);
  const { acceptFileTypes, token, room } = useAppStore((state) => {
    return {
      token: state.token,
      room: state.room,
      acceptFileTypes: state.settings.acceptFileTypes,
    };
  }, shallow);

  useEffect(() => {
    // and init must false detect if richmenu is open by default then change mode to richmenu mode
    if (
      richmenu &&
      richmenu.selected &&
      mode === EComposerMode.CHAT &&
      !isRichmenuInit.current
    ) {
      setMode(EComposerMode.RICH_MENU);
      isRichmenuInit.current = true;
      return;
    }
  }, [richmenu, mode, isRichmenuInit]);

  return {
    acceptFileTypes,
    richmenu,
    mode,
    textAreaRef,
    fileInputRef,
    message,
    onChangeMode: () => {
      if (mode === EComposerMode.CHAT) {
        setMode(EComposerMode.RICH_MENU);
        return;
      }
      if (isOpenRichmenu) {
        toggle();
      }
      setMode(EComposerMode.CHAT);
    },
    onType: (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      // check increase height
      if (textAreaRef.current) {
        // check if textarea height less than 208 px then increase it
        const { height } = textAreaRef.current.getBoundingClientRect();
        textAreaRef.current.style.height = "auto";
        if (height <= TEXT_AREA_MAX_HEIGHT) {
          textAreaRef.current.style.height =
            `${textAreaRef.current?.scrollHeight}px` ?? `40px`;
        } else {
          textAreaRef.current.style.cssText = `
          max-height: ${TEXT_AREA_MAX_HEIGHT}px;
          overflow-y: auto;
        `;
        }
      }
      setMessage(e.target.value);
    },
    onSend: async (msg?: string) => {
      if (!msg && !message) return;
      if (msg?.trim() === "" || message.trim() === "") {
        setMessage("");
        return;
      }
      setMessage("");
      const outgoing = await otgMsgGenerator({
        type: EMessageType.TEXT,
        msg: msg || message,
        rid: room._id,
        token: token,
      });
      // if null
      if (!outgoing) return;
      send("SEND_MESSAGE", outgoing);
    },
    onInputFileChange: async (e: ChangeEvent<HTMLInputElement>) => {
      const files = e.target.files;
      if (isEmpty(files)) return;

      const outgoing = await otgMsgGenerator({
        type: EMessageType.ATTACHMENT,
        file: files![0], // support only single attachment
        rid: room._id,
        token: token,
      });

      // if null
      if (!outgoing) return;
      // clear file input
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
      send("UPLOAD_ATTACHMENT", outgoing);
    },
  };
};
