import { faChevronDown, faChevronUp } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRichmenuStore } from "@store/richmenuStore";
import React from "react";
import { shallow } from "zustand/shallow";

export type IRichmenuComposerProps = {
  name: string;
};

export const RichmenuComposer = ({
  name,
}: React.PropsWithChildren<IRichmenuComposerProps>) => {
  const { isOpenRichmenu, toggle } = useRichmenuStore((state) => {
    return {
      isOpenRichmenu: state.isOpenRichmenu,
      toggle: state.toggleRichmenu,
    };
  }, shallow);
  return (
    <div className="flex h-[40px] w-full justify-center py-[8px]">
      <button onClick={() => toggle()} className="flex items-center">
        {name}
        <div className="ml-[8px]">
          {isOpenRichmenu ? (
            <div>
              <FontAwesomeIcon icon={faChevronDown} />
            </div>
          ) : (
            <div>
              <FontAwesomeIcon icon={faChevronUp} />
            </div>
          )}
        </div>
      </button>
    </div>
  );
};
