import bytes from "bytes";

export const ACCEPT_FILE_TYPES = [
  ".png",
  ".jpeg",
  ".gif",
  ".jpg",
  ".xlsx",
  ".pdf",
  ".csv",
  ".txt",
  ".zip",
  ".pptx",
  ".docx",
  ".mp4",
];

export const SIZE_TEXT = {
  IMAGE: "10MB",
  VIDEO: "50MB",
  FILE: "50MB",
};

// Max image size in bytes of `webchat` channel
export const WEBCHAT_MAX_IMAGE_BYTES = bytes.parse(SIZE_TEXT.IMAGE);
/**
 * Max video size in bytes of `webchat` channel
 */
export const WEBCHAT_MAX_VIDEO_BYTES = bytes.parse(SIZE_TEXT.VIDEO);
/**
 * Max file size in bytes of `webchat` channel
 */
export const WEBCHAT_MAX_FILE_BYTES = bytes.parse(SIZE_TEXT.FILE);
