import { EMessageStatus, IVideo } from "@types";
import { ImageLoader } from "./ImageLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/pro-light-svg-icons";
import { createDataAttr } from "@utils";

type VideoMessageProps = {
  message: IVideo;
};
export const VideoMessage = ({ message }: VideoMessageProps) => {
  // sending
  if (message.status === EMessageStatus.SENDING) {
    return (
      <div {...createDataAttr("video-message-sending")}>
        <div className="max-h-[240px] w-[240px] rounded-[10px] object-cover">
          <ImageLoader width={240} height={144} />
        </div>
      </div>
    );
  }
  // error
  if (message.status === EMessageStatus.ERROR) {
    return (
      <div {...createDataAttr("video-message-error")}>
        <div className="flex h-[50px] w-[240px] items-center justify-center rounded-[10px] bg-[#dbdbdb] object-cover">
          <FontAwesomeIcon icon={faCircleExclamation} fontSize={24} />
        </div>
      </div>
    );
  }
  return (
    <video
      className="h-[240px] max-h-[240px] w-[240px] rounded-[10px] object-cover"
      controls
      {...createDataAttr("video-message")}
    >
      <source src={message.originalContentUrl} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  );
};
