import { Livechat } from "@api";
import { createToken } from "@utils";

export const setRocketToken = (token: string) => {
  if (!token) {
    return false;
  }
  Livechat.credentials.token = token;
  return true;
};

export const genrateToken = (existingToken?: string, newToken?: string) => {
  let token = "";
  // create new token if no exist and no newToken
  if (!existingToken && !newToken) {
    token = createToken();
  }
  //token in system does not exist and there is token from iframe
  else if (!existingToken && newToken) {
    token = newToken;
  }
  // if new token is not equal to existing token set existing token to new token
  else if (existingToken && newToken && newToken !== token) {
    token = newToken;
  }
  // if already have token
  else if (existingToken && !newToken) {
    token = existingToken;
  }
  return token;
};
